import React from "react";
import '../../styles/semantic.min.css';
import SEO from "../../components/seo/Seo";
import LayoutContainer from "../../container/LayoutContainer";
import PageHeader from "../../components/utils/PageHeader";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Button, Container, Icon, Segment} from "semantic-ui-react";
import layout from "../../templates/layout.module.css";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";

export default () => <LayoutContainer>
    <SEO title={'Danke'}/>
    <MobileTabletContainer><MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank'}/>
            <Container>
                <Segment>
                    <p>Ihr Bild und Ihre Botschaft wurden erfolgreich hochgeladen. Vielen Dank für Ihre
                        Unterstützung. Ihre Daten werden von uns zuerst gesichtet und bei nächster Gelegenheit
                        freigeschalten.</p>
                    <Button basic as={'a'} href={'/'}><Icon name={'home'}/>Zurück zur Startseite</Button>
                    <Button basic color={'blue'} as={'a'} href={'/freunde/'} style={{marginLeft: '2rem'}}><Icon
                        name={'smile outline'}/>Unsere Freunde ansehen</Button>
                </Segment>
            </Container>
        </Container>
        <Footer/>

    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <Icon name={'check circle'} size={'massive'} color={'green'}
                  style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
            <PageHeader title={'Vielen Dank'}/>
            <Container>
                <Segment style={{marginBottom: "2rem"}}>
                    <p>Ihr Bild und Ihre Botschaft wurden erfolgreich hochgeladen. Vielen Dank für Ihre
                        Unterstützung. Ihre Daten werden von uns zuerst gesichtet und bei nächster Gelegenheit
                        freigeschalten.</p>
                    <Button basic as={'a'} href={'/'}><Icon name={'home'}/>Zurück zur Startseite</Button>
                    <Button basic color={'blue'} as={'a'} href={'/freunde/'} style={{marginLeft: '2rem'}}><Icon
                        name={'smile outline'}/>Unsere Freunde ansehen</Button>
                </Segment>
            </Container>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>